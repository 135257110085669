import logger, { LEVEL } from '../components/logger';
import dlv from 'dlv';

const PREBID_TIMEOUT = 4000;

let config;
let firstLoad = true;
let myLog;
let provider;
let providerConfig;
let currentRequest;
let allPrebidSlots = {};
let sequence = 0;
let prebidConfig;
let requestedSlotNames;
let defaultBidderParams;
let bidsReceived = {};
let testMode = false;
let currentAuctionId;
let schain = {};

/**
 * Sadlib Test Mode Params:
 * localStorage.sadlibTestModeParams = `{
 *   "class":"prebid_bidders"
 * }`;
 */

export default function initialize(name, cfg) {
	myLog = logger('TargetingProvider:' + name, cfg.verbosity);
	myLog(LEVEL.FLOW, 'Initializing');

	testMode = false;
	if (cfg.testMode && cfg.testModeParams.class.indexOf('prebid_bidders') > -1) {
		testMode = true;
		myLog(LEVEL.WARN, 'Test Mode Enabled');
	}

	require('../ext/prebid.js');
	window.pbjs = window.pbjs || {};
	window.pbjs.que = window.pbjs.que || [];

	if (testMode) {
		myLog(LEVEL.CONFIG, 'Putting prebid into debug mode.');
		window.pbjs.que.push(() => {
			pbjs.setConfig({ debug: true });
		});
	}

	config = cfg;
	providerConfig = cfg.targetingProviders[name];
	provider = name;
	prebidConfig = providerConfig.configuration;
	defaultBidderParams = providerConfig.defaultBidderParams;

	pbjs.que.push(() => {
		getSchain(() => {
			if (schain) {
				pbjs.setConfig({
					schain: {
						validation: 'strict',
						config: schain
					}
				});
			}
		});
	});

	//TODO: Can these be moved to the config????
	pbjs.que.push(() => {
		pbjs.bidderSettings = {
			appnexus: {
				storageAllowed: true
			},
			criteo: {
				storageAllowed: true
			},
			ix: {
				storageAllowed: true
			},
			taboola: {
				storageAllowed: true
			}
		};
	});

	return {
		attachUnit,
		run
	};
}

/**
 * @param {object} unit - Configuration object for the ad unit
 */
function attachUnit(unit) {
	let unitSizes = unit.sizes;
	let unitName = unit.name;

	// Prebid config object doesn't allow 'fluid' size so we need exclude it.
	const fluidIndex = unitSizes.indexOf('fluid');
	if (fluidIndex > -1) {
		unitSizes.splice(fluidIndex, 1);
	}

	if (unitName) {
		let slotDivId = unit.slot.getSlotElementId();
		let bidders = Object.keys(defaultBidderParams);
		let bids = [];

		bidders.forEach((bidder) => {
			let bid = JSON.parse(JSON.stringify(defaultBidderParams[bidder]));
			if ((prebidConfig && prebidConfig.bidders && prebidConfig.bidders[bidder]) || bid.skipSass) {
				let bidderConfigKeys = bid.skipSass ? [] : Object.keys(prebidConfig.bidders[bidder]);
				let bidderConfig = bid.skipSass ? bid.params : prebidConfig.bidders[bidder];
				let slotId = null;
				let slotIdKeys = ['slot_ids', 'placement_ids', 'unit_settings', 'zone_ids'];

				slotIdKeys.forEach((key) => {
					if ( bidderConfigKeys.indexOf(key) !== -1 ) {
						slotId = bidderConfig[key][unitName];
					}
				});
				// Criteo is the only bidder that doesn't need slot level IDs.

				let consumableSplitUnitSettings = null;
				if ((bidderConfig.setEnabled === 'yes' && (slotId || ['criteo', 'vidazoo'].includes(bidder))) || bid.skipSass) {
					switch (bidder) {
						case 'appnexus':
							bid.params.placement_id = slotId;
							break;

						case 'consumable':
							bid.params.siteId = parseInt(bidderConfig.siteId, 10);
							bid.params.networkId = parseInt(bidderConfig.networkId, 10);
							consumableSplitUnitSettings = slotId.split(',');
							if (Array.isArray(consumableSplitUnitSettings) && consumableSplitUnitSettings.length >= 3) {
								bid.params.unitName = consumableSplitUnitSettings[0];
								bid.params.unitId = parseInt(consumableSplitUnitSettings[1], 10);
								bid.params.zoneIds = parseInt(consumableSplitUnitSettings[2], 10);
							}
							break;

						case 'criteo':
							break;

						case 'brealtime':
							bid.params.tagid = slotId;
							break;

						// Index Exchange
						case 'casale':
							bid.params.siteId = slotId;
							bid.params.size = unitSizes[0];
							break;

						case 'pubmatic':
							bid.params.publisherId = bidderConfig.setId;
							bid.params.adSlot = slotId;
							break;

						case 'synacormedia':
							bid.params.seatId = bidderConfig.setId;
							bid.params.placementId = slotId;
							bid.params.pos = getPos(unitName);
							break;

						case 'taboola':
							bid.params.tagId = bidderConfig.tagId + unitName;
							bid.params.publisherId = bidderConfig.publisherId;
							bid.params.position = getPos(unitName);
							break;

						case 'vidazoo':
							break;
					}

					bids.push(bid);
				}

			}
		});

		allPrebidSlots[slotDivId] = {
			code: slotDivId,
			bids,
			mediaTypes: {
				banner: {
					pos: getPos(unitName),
					sizes: unitSizes
				}
			}
		};

		// set properties needed for https://docs.prebid.org/dev-docs/modules/gpt-pre-auction.html
		if (unit.path) {
			// we only need one of `ortb2Imp.ext.gpid` or
			// `ortb2Imp.ext.data.pbadslot`, but we'll add both to be
			// safe
			allPrebidSlots[slotDivId].ortb2Imp = {
				ext: {
					gpid: unit.path,
					data: {
						pbadslot: unit.path
					}
				}
			};
		}

		if (unit.native) {
			allPrebidSlots[slotDivId].mediaTypes = {
				native: {
					sizes: unitSizes
				}
			};
		}
	}
}

/**
 * Add any requested slots to prebid and return the array of codes.
 * This will loop over all requested slots and if any have not already been added to prebid previously
 * it will add them.
 * Then it returns an array of codes that should be set in the prebid request object before calling requestBids.
 *
 * @param {import("../components/TargetingProviderRequest").default} req
 */
function addSlotsToPrebid(req) {
	//If this isn't our first call, get any slot codes already added to prebid
	/** @type {string[]} */
	let existingSlotCodes = (pbjs.adUnits) ? pbjs.adUnits.map((prebidUnit) => prebidUnit.code) : [];

	//For each requested slot, if it isn't already added to prebid, add it.

	/** @type {import("../components/AdUnit").default[]} */
	let slotsToAdd = [];

	/** @type string[] */
	let requestedSlotCodes = [];

	req.units.forEach((slot) => {
		if (existingSlotCodes.indexOf(slot.divid) === -1) {
			//We have not requested this slot before, add it to prebid
			if (allPrebidSlots[slot.divid]) {
				slotsToAdd.push(allPrebidSlots[slot.divid]);
			} else {
				myLog(LEVEL.ERROR, 'Prebid was requested to add a slot that was not attached. This should not happen.', slot, allPrebidSlots);
			}
		}
		//Get requested slot codes
		requestedSlotCodes.push(slot.divid);
	});

	const floorValues = getFloors(req.units);
	if (floorValues && Object.keys(floorValues).length !== 0) {
		pbjs.setConfig({
			floors: {
				enforcement: {
					enforceJS: true
				},
				data: {
					currency: 'USD',
					schema: {
						delimiter: '|',
						fields: ['adUnitCode', 'mediaType', 'size']
					},
					values: floorValues
				}
			}
		});
	}

	if (slotsToAdd.length) {
		pbjs.addAdUnits(slotsToAdd);
	}

	if (firstLoad) {
		firstLoad = false;
	}

	//Return the requested slot codes.
	return requestedSlotCodes;
}

/**
 * @param {import("../components/TargetingProviderRequest").default} req
 */
function run(req) {
	requestedSlotNames = req.units.map((unit) => unit.config.name);

	if (currentRequest) {
		myLog(LEVEL.WARN, 'Multiple requests tried at the same time. This should not happen. Will abandon previous request.');
	}
	currentRequest = req;

	let tOut = firstLoad ? PREBID_TIMEOUT : config.refreshTimeout ? (PREBID_TIMEOUT + config.refreshTimeout) : PREBID_TIMEOUT;

	pbjs.que.push(() => {
		sequence++;

		let requestedSlotCodes = addSlotsToPrebid(currentRequest);
		currentAuctionId = performance.now();
		let prebidRequest = {
			adUnitCodes: requestedSlotCodes,
			bidsBackHandler: setPrebidTargeting,
			timeout: tOut,
			auctionId: currentAuctionId
		};

		const customPriceGranularity = {
			buckets: [{
				precision: 2,  //default is 2 if omitted - means 2.1234 rounded to 2 decimal places = 2.12
				max: 10,
				increment: 0.01  // from $0.02 to $9.99, 1-cent increments
			},
			{
				precision: 2,
				max: 20,
				increment: 0.05  // from $10 to $19.99, round down to the previous 5-cent increment
			},
			{
				precision: 2,
				max: 50,
				increment: 0.5   // from $20 to $50, round down to the previous 50-cent increment
			}]
		};

		const pbjsTimeout = (providerConfig && providerConfig.configuration && providerConfig.configuration.setPrebidTimeout) ? parseInt(providerConfig.configuration.setPrebidTimeout, 10) : 3000;
		// Set custom config options
		let pbjsConfig = {
			userSync: {
				filterSettings: {
					all: {
						bidders: '*',
						filter: 'include'
					}
				},
				syncDelay: 3000
			},

			priceGranularity: customPriceGranularity,
			bidderTimeout: pbjsTimeout
		};

		if (window.__gpp || config.enableGpp) {
			pbjsConfig.consentManagement = {
				gpp: {
					cmpApi: 'iab',
					timeout: 8000
				}
			};
		}

		if (config && config.targetingProviders && config.targetingProviders.liveramp && config.targetingProviders.liveramp.enabled) {
			pbjsConfig.userSync.userIds = [
				{
					name: 'identityLink',
					params: {
						pid: config && config.targetingProviders && config.targetingProviders.liveramp && config.targetingProviders.liveramp.placementId ? config.targetingProviders.liveramp.placementId : '2101'
					},
					storage: {
						type: 'cookie',
						name: 'idl_env',
						expires: 3
					}
				},
				{
					name: 'nextrollId',
					params: {
						partnerId: config && config.targetingProviders && config.targetingProviders.nextroll && config.targetingProviders.nextroll.partner_id ? config.targetingProviders.nextroll.partner_id : ''
					}
				},
				{
					name: 'pubCommonId',
					storage: {
						type: 'html5',
						name: '_pubcid',
						expires: 180
					}
				},
				{
					name: 'publinkId',
					storage: {
						type: 'cookie',
						name: 'pbjs_publink',
						expires: 30
					},
					params: {
						e: (config.publinkId.emailHashes && config.publinkId.emailHashes.length) ? config.publinkId.emailHashes[0] : '',
						site_id: config.publinkId.siteId,
						api_key: config.publinkId.apiKey
					}
				}];
		}

		pbjs.setConfig(pbjsConfig);

		bidsReceived = {};
		pbjs.offEvent('auctionEnd', auctionEndHandler); //Remove any previous handlers
		pbjs.onEvent('auctionEnd', auctionEndHandler);

		const auctionInitHandler = () => {
			pbjs.offEvent('auctionInit', auctionInitHandler);

			// This MUST run AFTER pbjs.requestBids instantiate's pbjs's
			// global auction structure for each `slotCode`. It MUST
			// also be run BEFORE the 'bidWon' events are dispatched.
			// As of Prebid.js 7.19.0, the 'auctionInit' event appears
			// to work although Prebid.js lacks documentation as to when
			// calling pbjs.(on|off)Event('bidWon',...,slotCode) is safe.
			requestedSlotCodes.forEach((slotCode) => {
				myLog(LEVEL.FLOW, `Attaching bidWon handler for ${slotCode}.`);
				pbjs.offEvent('bidWon', bidWonHandler, slotCode); //Remove any previous handlers
				pbjs.onEvent('bidWon', bidWonHandler, slotCode);
			});
		};
		pbjs.onEvent('auctionInit', auctionInitHandler);

		myLog(LEVEL.FLOW, `Requesting bids for auction #${sequence} (id: ${currentAuctionId}).`);
		pbjs.requestBids(prebidRequest);
	});
}

function getSchain(cb) {
	schain = {
		ver: '1.0',
		complete: 1,
		nodes: [
			{
				asi: 'imds.tv',
				hp: 1
			}
		]
	};

	// Try to get seller_id  from localStorage
	let sid = localStorage.getItem('sid');

	if (sid) {
		schain.nodes[0].sid = sid;
		return cb ? cb() : null;
	}

	// Fallback: Fetch from CDN if sid is not in localStorage
	let xhr = new XMLHttpRequest();
	const sellersDotJsonUrl = (config && config.sellersDotJsonUrl) ? config.sellersDotJsonUrl : 'https://contango-cdn.technoratimedia.com/sellers.json';
	let siteId = 'synacor';
	xhr.open('GET', sellersDotJsonUrl, true);
	xhr.responseType = 'json';
	xhr.onload = function() {
		const status = xhr.status;
		if (status === 200) {
			const sellersData = xhr.response.sellers;
			// Build an array of siteIds from siteId and siteAliases. We need siteAliases to account for branding changes and
			// inconsistencies between sellers.json data and our internal identifiers within the Sadlib/portal codebase.
			let siteIds = [];
			if (config) {
				if (config.siteId) {
					siteId = config.siteId;
					if (siteId.indexOf('gen4') > -1) {
						siteId = siteId.substring(0, siteId.length - 5);
					}
					siteIds.push(siteId.toLowerCase());
				}
				if (Array.isArray(config.siteAliases)) {
					siteIds = siteIds.concat(config.siteAliases.map(alias => alias.toLowerCase()));
				}
			}

			// Filter sellersData to get matching seller IDs based on any siteId being a substring in seller.name
			let sidList = sellersData
				.filter(seller =>
					siteIds.some(siteId => seller.name.toLowerCase().indexOf(siteId) > -1)
				)
				.map(seller => seller.seller_id);

			if (Array.isArray(sidList) && sidList.length >= 1) {
				sid = sidList[0].toString();
				localStorage.setItem('sid', sid);
				schain.nodes[0].sid = sid;
			} else {
				return null;
			}

			if (cb) {
				return cb();
			}
		} else {
			return null;
		}
	};
	xhr.send();
}

/**
 * This is called after the creative renders, as an end-to-end success beacon.
 */
function bidWonHandler(data) {
	//NOTE: We could add code here to ignore wins if the auctionId doesn't match - but I don't know if that is a good idea or not.
	// let winningBid = {
	// 	price: Number(data.cpm.toFixed(2)),
	// 	actual: data.cpm,
	// 	size: data.size,
	// 	creative: data.ad,
	// 	used: Date.now()
	// };
	myLog(LEVEL.FLOW, `Logging win notice for bidder ${data.bidder} for ad unit ${data.adUnitCode.substr(11)} for auction #${sequence} (id: ${data.auctionId}).`);
	//PrebidLogger.win(bidsReceived.bidders, data.bidder, data.adUnitCode.substr(11), winningBid, sequence);
	myLog(LEVEL.FLOW, `Removing bidWon handler for ${data.adUnitCode}.`);
	pbjs.offEvent('bidWon', bidWonHandler, data.adUnitCode);
}

/**
 * Callback for Prebid.js's `auctionEnd` event
 */
function auctionEndHandler(data) {
	if (data.auctionId !== currentAuctionId) {
		//If this event handler was called by a different auction, ignore it.
		myLog(LEVEL.WARN, `Note: Prebid's auctionEndHandler was called but not for the auction id we expected. Ignoring these bids. Auction id that made the call: ${data.auctionId} Auction id we were expecting: ${currentAuctionId}`);
		return;
	}
	data.bidsReceived.forEach((bid) => {
		myLog(LEVEL.INFO, `Bid received: ${bid.bidder} (${bid.adUnitCode}) = ${bid.cpm}`);
	});
	let auctionBegin = data.timestamp;
	let auctionEnd = data.auctionEnd;
	let status = 'SUCCESS';
	let bidderNames = data.bidderRequests.map(bidder => bidder.bidderCode);
	bidsReceived = {
		bidders: {},
		start: auctionBegin,
		end: auctionEnd
	};
	/* eslint-disable max-nested-callbacks */
	bidderNames.forEach((bidderName) => {
		bidsReceived.bidders[bidderName] = {
			start: auctionBegin,
			end: auctionEnd,
			status,
			bids: {}
		};
		requestedSlotNames.forEach((adUnit) => {
			bidsReceived.bidders[bidderName].bids[adUnit] = [];

			let validBid = data.bidsReceived.filter((bid) => (bid.bidderCode === bidderName) && (bid.adUnitCode === 'div-gpt-ad-' + adUnit));
			if (validBid && validBid.length) {
				validBid = validBid[0];
				bidsReceived.bidders[bidderName].start = validBid.requestTimestamp;
				bidsReceived.bidders[bidderName].end = validBid.responseTimestamp;
				bidsReceived.bidders[bidderName].status = 'SUCCESS';
				bidsReceived.bidders[bidderName].bids[adUnit] = [{ price: Number(validBid.cpm.toFixed(2)), actual: validBid.cpm, size: validBid.size, creative: validBid.ad }];
			}
		});
	});
	/* eslint-disable max-nested-callbacks */
	pbjs.offEvent('auctionEnd', auctionEndHandler);
	//PrebidLogger.bids(bidsReceived, sequence, refreshRequest);
	if (data.bidsReceived.length > 0) {
		myLog(LEVEL.FLOW, `Logged bids for auction #${sequence} (id: ${currentAuctionId}).`);
	} else {
		myLog(LEVEL.FLOW, `Logged no bids for auction #${sequence} (id: ${currentAuctionId}).`);
		afterAuctionHelper();
	}
}

/**
 * Callback for PBJS's `bidsBackHandler`. Called after Prebid.js
 * receives a response to every bid request or encounters a timeout
 * while running an auction.
 * @param {object} bids
 * @param {Boolean} timedOut
 * @param {String} auctionId
 */
function setPrebidTargeting(bids, timedOut, auctionId) {
	if (bids && Object.keys(bids).length) {
		afterAuctionHelper(auctionId);
	}
}

/**
 * Helper function that performs tasks after an auction has ended.
 */
function afterAuctionHelper(auctionId) {
	// This code *can* run multiple times for a single Prebid.js auction.
	// * There's multiple ways this function might be called through
	//   Prebid.js callbacks.
	// * With how Sadlib works, if an ad refresh happens before
	//   Prebid.js has completed an auction, that auction "times out."
	//   After the "time out" Prebid.js could hypothetically fire off a
	//   callback after Sadlib has started a new auction with a new
	//   `currentRequest` and `currentAuctionId`.

	googletag.cmd.push(() => {
		try {
			if (auctionId !== currentAuctionId) {
				//If this event handler was called by a different auction, ignore it.
				myLog(LEVEL.WARN, `Note: A Prebid event handler was called but not for the auction id we expected. Ignoring these bids. Auction id that made the call: ${auctionId} Auction id we were expecting: ${currentAuctionId}`);
				return;
			}

			if (!currentRequest) {
				myLog(LEVEL.WARN, `Note: A Prebid event handler was called but the expected Sadlib request was null`);
				return;
			}

			pbjs.setTargetingForGPTAsync();
			currentRequest.handleProviderFinished(provider);
			currentRequest = null;
		} catch (e) {
			myLog(LEVEL.ERROR, `An error occured in a Sadlib Prebid.js event handler: ${e}`);
		}
	});
}

/**
 * Parse the floor rules from ad units config to an object in a format expected by Prebid.
 * @param {import("../components/AdUnit").default[]} units
 * @returns {Object.<string, number>}
 */
function getFloors(units) {
	let floorValues = {};

	units.forEach((unit) => {
		// get floors first from unit config
		let floors = dlv(unit, 'config.floors');
		// if not defined, try from overall prebid config for that type
		if (typeof floors !== 'object' && dlv(unit, 'config.class')) {
			floors = dlv(unit, 'sadlibConfig.targetingProviders.prebid_bidders.configuration.floors.' + unit.config.class);
		}
		if (typeof floors === 'object') {
			if (!firstLoad && unit.config.dropFloorsOnRefresh) {
				return;
			}

			for (const [key, value] of Object.entries(floors)) {
				unit.divid = unit.divid || '';
				const floorKey = unit.divid + '|banner|' + key;
				const floorValue = parseFloat(value);
				floorValues[floorKey] = floorValue;
			}
		}
	});
	return floorValues;
}

function getPos(slot) {
	const atfSlotNames = ['masthead', 'home', 'home_mtf', 'adhesion', 'sidekick', 'dspotlight', 'atf_news'];
	const IAB_POSITIONS = {
		UNKNOWN: 0,
		ATF: 1,
		BTF: 3,
		HEADER: 4,
		FOOTER: 5,
		SIDEBAR: 6,
		FULL_SCREEN: 7
	};
	if (atfSlotNames.indexOf(slot) > -1 || slot.toLowerCase().indexOf('atf') > -1) {
		return IAB_POSITIONS.ATF;
	}
	return IAB_POSITIONS.BTF;
}
